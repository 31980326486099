* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: FuturaPT;
}

@font-face {
  font-family: FuturaPT;
  src: url(../src/components/fonts/futura-pt/FuturaPTLight.woff2);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: FuturaPT;
  src: url(../src/components/fonts/futura-pt/FuturaPTMedium.woff2);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: FuturaPT;
  src: url(../src/components/fonts/futura-pt/FuturaPTBold.woff2);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: FuturaPT;
  src: url(../src/components/fonts/futura-pt/FuturaPTExtraBold.woff2);
  font-weight: 800;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200,900');

a {
  text-decoration: none !important;
}

/* global button start  */

.globalanimatedbtn {
  background: linear-gradient(to left top, #e50000 50%, #e50000 50%);
  border-style: none;
  color: #fff;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all .5s;
  font-family: "Source Code Pro";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 22px !important;
  line-height: 50px;
  height: 50px;
  padding: 0px 20px;
  box-shadow: hsla(210, 40%, 52%, .4) 2px 2px 22px;
  border-radius: 4px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
}

.globalanimatedbtn span {
  position: absolute;
  display: block;
}

.globalanimatedbtn span:nth-child(1) {
  height: 3px;
  width: 200px;
  top: 0px;
  left: -200px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #f6e58d);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span1 2s linear infinite;
  animation-delay: 1s;
}

@keyframes span1 {
  0% {
    left: -200px
  }

  100% {
    left: 200px;
  }
}

.globalanimatedbtn span:nth-child(2) {
  height: 70px;
  width: 3px;
  top: -70px;
  right: 0px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #f6e58d);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span2 2s linear infinite;
  animation-delay: 2s;
}

@keyframes span2 {
  0% {
    top: -70px;
  }

  100% {
    top: 70px;
  }
}

.globalanimatedbtn span:nth-child(3) {
  height: 3px;
  width: 200px;
  right: -200px;
  bottom: 0px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #f6e58d);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  animation: span3 2s linear infinite;
  animation-delay: 3s;
}

@keyframes span3 {
  0% {
    right: -200px;
  }

  100% {
    right: 200px;
  }
}

.globalanimatedbtn span:nth-child(4) {
  height: 70px;
  width: 3px;
  bottom: -70px;
  left: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), #f6e58d);
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  animation: span4 2s linear infinite;
  animation-delay: 4s;
}

@keyframes span4 {
  0% {
    bottom: -70px;
  }

  100% {
    bottom: 70px;
  }
}

.globalanimatedbtn:hover {
  transition: all .5s;
  transform: rotate(-1deg) scale(1.05);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, .4);
}

.globalanimatedbtn:hover span {
  animation-play-state: paused;
}


/* global button end  */


.container-max {
  max-width: 1250px;
  margin: auto;
}

header {
  position: fixed;
  width: 100%;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
  z-index: 99999999;
}

.react-progress-bar-percent {
  background: #e50000 !important;
  box-shadow: 0 0 10px #e50000, 0 0 5px #e50000 !important;
}

.react-progress-bar {
  z-index: 9999999999999999999999999 !important;
}

header .container-max {
  display: grid;
  grid-template-columns: 2fr .3fr 2fr;
  text-align: center;
  width: 100%;
  align-items: center;
  padding: 30px 0;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
  max-width: 1275px;
}

header .container-max .menus ul {
  padding: 0;
  margin: 0;
  text-align: left;
}

header li {
  list-style: none;
  display: inline-block;
}

header .container-max .menus ul li:nth-child(2) {
  margin-left: 0px;
}

.Nav-Main li a {
  color: #fff;
  text-decoration: none;
  position: relative;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 15px;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
}

body.home-page-body header.header-normal svg path {
  fill: #e50000;
}

.animated-float-btn {
  position: relative;
  padding: 0px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-family: "Source Code Pro";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 22px !important;
  background-color: #e50000;
  box-shadow: hsla(210, 40%, 52%, .4) 2px 2px 22px;
  border-radius: 4px;
  z-index: 0;
  overflow: hidden;
  color: white !important;
}

.animated-float-btn:focus {
  outline-color: transparent;
  box-shadow: hsl(210, 80%, 42%) 2px 2px 22px;
}

.right::after,
.animated-float-btn::after {
  content: "LET'S TALK";
  display: block;
  position: absolute;
  white-space: nowrap;
  padding: 30px 40px;
  pointer-events: none;
}

.animated-float-btn::after {
  font-weight: 200;
  top: -30px;
  left: -20px;
}

.right,
.left {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.right {
  left: 66%;
}

.left {
  right: 66%;
}

.right::after {
  top: -30px;
  left: calc(-66% - 20px);

  background-color: #141218;
  color: transparent;
  transition: transform .4s ease-out;
  transform: translate(0, -90%) rotate(0deg)
}

.animated-float-btn:hover .right::after {
  transform: translate(0, -47%) rotate(0deg)
}

.animated-float-btn .right:hover::after {
  transform: translate(0, -50%) rotate(-7deg)
}

.animated-float-btn .left:hover~.right::after {
  transform: translate(0, -50%) rotate(7deg)
}

/* bubbles */
.animated-float-btn::before {
  content: '';
  pointer-events: none;
  opacity: .8;
  background:
    radial-gradient(circle at 20% 35%, transparent 0, transparent 2px, hsla(210, 50%, 85%, 1) 3px, hsla(210, 50%, 85%, 1) 4px, transparent 4px),
    radial-gradient(circle at 75% 44%, transparent 0, transparent 2px, hsla(210, 50%, 85%, 1) 3px, hsla(210, 50%, 85%, 1) 4px, transparent 4px),
    radial-gradient(circle at 46% 52%, transparent 0, transparent 4px, hsla(210, 50%, 85%, 1) 5px, hsla(210, 50%, 85%, 1) 6px, transparent 6px),
    radial-gradient(circle at 33% 23%, transparent 0, transparent 4px, hsla(210, 50%, 85%, 1) 5px, hsla(210, 50%, 85%, 1) 6px, transparent 6px),
    radial-gradient(circle at 73% 13%, transparent 0, transparent 4px, hsla(210, 50%, 85%, 1) 5px, hsla(210, 50%, 85%, 1) 6px, transparent 6px);

  width: 100%;
  height: 300%;
  top: 0;
  left: 0;
  position: absolute;
  animation: bubbles 5s linear infinite both;
}

@keyframes bubbles {
  from {
    transform: translate();
  }

  to {
    transform: translate(0, -66.666%);
  }
}

header li a:hover,
.header-cta a:nth-child(1):hover {
  color: #fff;
}

.header-cta a:nth-child(1) {
  margin-right: 30px;
  display: none;
}

.header-cta a:nth-child(2):hover {
  color: #010101;
  background-color: #e50000;
}

.header-cta a:nth-child(1) {
  padding: 0 15px;
}

.Nav-Main li a::after,
.header-cta a:nth-child(1)::after {
  content: url(https://www.bluefrontier.co.uk/templates/bootstrap4/images/menu-hover-right.svg);
  vertical-align: middle;
  margin-left: 5px;
  display: inline-block;
  opacity: 0;
  height: 0;
  transition: 0.1s all;
  position: absolute;
}

.Nav-Main li a::before,
.header-cta a:nth-child(1)::before {
  content: url(https://www.bluefrontier.co.uk/templates/bootstrap4/images/menu-hover-left.svg);
  vertical-align: middle;
  margin-right: 5px;
  opacity: 0;
  display: inline-block;
  height: 0;
  position: absolute;
  transition: 0.1s all;
}

.Nav-Main li a::before,
.header-cta a:nth-child(1):before {
  left: 0;
  transform: translate(0%, -50%);
  top: 50%;
}

.Nav-Main li a::after,
.header-cta a:nth-child(1):after {
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}

.Nav-Main li a:hover::after,
.Nav-Main li a:hover::before,
.header-cta a:nth-child(1):hover::after,
.header-cta a:nth-child(1):hover::before {
  opacity: 1;
  transition: 0.1s all;
  height: 30px;
  filter: invert(1);
}

.Nav-Main li a.active::after,
.Nav-Main li a.active::before {
  opacity: 1;
  transition: 0.1s all;
  height: 30px;
  filter: invert(1);
}

a.btn2.active {
  background-color: white;
  color: #e50000;
}

.svg.svg--ruckus-logo img {
  width: 100%;
  height: 100%;
}

.header-cta a {
  color: white;
  letter-spacing: 1px;
  text-decoration: none;
  font-size: 18px;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
  line-height: 50px;
  height: 50px;
  position: relative;
}

body.home-page-body header li a {
  color: #000;
}

body.home-page-body header.header-normal a.logo-link:after {
  opacity: 1;
}

body.home-page-body header.header-normal .header-cta a {
  color: #000;
}

body.home-page-body a.btn2 {
  border: 0.5px solid #000;
}

a.btn2 {
  border: 0.5px solid white;
  padding: 15px 25px 14px 28px;
  margin-right: 0px;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 20px;
}

.header-cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

header a.logo-link {
  width: 70px;
  margin: auto;
}

header a.logo-link {
  position: relative;
}

.svg.svg--ruckus-logo {
  position: relative;
  z-index: 2;
}

header.header-normal.header-bg {
  background: white;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
  box-shadow: 0px 0px 8px 1px #00000021;
}

header.header-normal.header-bg li a {
  color: #292929;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
}

header.header-normal.header-bg .container-max {
  padding: 9px 0;
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
}

header.header-normal.header-bg .header-cta a {
  transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
  color: #e50000;
}

.svg--ruckus-logo path {
  stroke: #ffff;
  stroke-width: 0.5;
}

header.header-normal.header-bg .svg--ruckus-logo path,
body.home-page-body header path {
  stroke: #e50000;
}

header.header-normal.header-bg .header-cta a:nth-child(2):hover {
  color: white;
  background-color: #e50000;
}

header.header-normal.header-bg a.btn2 {
  border: 0.5px solid #e50000;
}


.button-content.z2,
.b8.z2 {
  display: flex;
  align-items: center;
  font-size: 21px;
  line-height: 23px;
  font-weight: bold;
  text-transform: capitalize;
  width: fit-content;
  background: #e50000;
  color: #fff;
  height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  letter-spacing: 1px;
}

.svg.svg--arrow.button-svg_arrow {
  width: 20px;
  margin-left: 12px;
}









/* new hero css start  */

.flex-hero-chrome .container-max {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18%;
  padding-bottom: 11%;
}

.fiftypercnt {
  width: 50%;
}

.flex-left-home h1 {
  font-size: 60px;
  font-weight: 800;
}

.flex-hero-chrome {
  position: relative;
}

.right-empty-hero.fiftypercnt {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
}

.right-empty-hero.fiftypercnt img {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  object-position: -88px;
}

.right-empty-hero.fiftypercnt::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: 200%;
  background-position: 20% 45%;
  background-repeat: no-repeat;
  background-image: url(https://assets.sabre.com/images/corporate/gateway-white.svg);
  z-index: 2;
}

.flex-left-home h1 {
  font-size: 46px;
  font-weight: 800;
  color: #e50000;
  font-family: Roboto, Arial, sans-serif;
  line-height: 49px;
  padding-left: 1.3rem;
  position: relative;
}

.flex-left-home h1::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  bottom: 0;
  width: 0.23rem;
  height: calc(100% - 0.2em);
  margin: 0;
  background: #e50000;
}

.flex-left-home.fiftypercnt p {
  font-size: 19px;
  margin: 26px 0 20px;
}

.ratin-stars-cstm svg {
  color: #ececec;
  width: 18px;
  fill: #ffcc00;
  margin-bottom: -1px;
}

.ratin-stars-cstm {
  display: flex;
  gap: 10px;
  margin: 19px 0 0 0;
  font-size: 18px;
  letter-spacing: 1px;
  font-style: italic;
  align-items: center;
}

body.about-page header path {
  color: white;
}

body.about-page header.header-normal.header-bg path {
  color: #e50000;
}

@media(max-width:1250px) {
  .flex-left-home h1 {
    font-size: 48px;
    line-height: 53px;
  }

  .flex-hero-chrome .container-max {
    padding-top: 17%;
    padding-bottom: 7%;
  }

  .flex-left-home.fiftypercnt p {
    font-size: 25px;
    line-height: 28px;
  }
}

@media(max-width:975px) {
  .flex-left-home h1 {
    font-size: 43px;
    line-height: 47px;
    padding-left: 1.2rem;
  }
}

@media(max-width:875px) {
  .fiftypercnt {
    width: 75%;
    position: relative;
    z-index: 2;
  }

  .right-empty-hero.fiftypercnt {
    z-index: 1;
  }

  .flex-left-home h1 {
    font-size: 53px;
    line-height: 60px;
  }

  .right-empty-hero.fiftypercnt::before {
    /* background: #00000042; */
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }
}

@media(max-width:768px) {
  .right-empty-hero.fiftypercnt {
    width: 80%;
  }
}

@media(max-width:500px) {
  .ratin-stars-cstm {
    font-size: 15px;
    gap: 5px;
    letter-spacing: .5px;
    margin: 10px 0 0;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
  }

  .flex-hero-chrome .container-max {
    padding-top: 130px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }

  .flex-left-home h1 {
    font-size: 31px;
    line-height: 36px;
    color: white;
  }

  .flex-left-home.fiftypercnt p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    color: white;
  }




  .right-empty-hero.fiftypercnt::after {
    display: none;
  }

  .right-empty-hero.fiftypercnt img {
    object-position: unset;
  }

  .flex-left-home h1::after {
    background: #ffffff;
  }

  .globalanimatedbtn {
    font-size: 17px !important;
    line-height: 40px;
    height: 40px;
    padding: 0px 13px;
  }

  .fiftypercnt {
    width: 100%;
  }

  .right-empty-hero.fiftypercnt {
    bottom: 0;
    width: 100%;
    top: unset;
    height: 100%;
    top: 0;
  }







}







/* new hero css end  */










.carousel-control-prev {
  left: -5% !important;
}

.carousel-control-next {
  right: -5% !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem !important;
  height: 3rem !important;
}

a.carousel-control-prev {
  opacity: 0 !important;
  transform: translateX(0%);
  transition: all 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
}

.Hero:hover a.carousel-control-prev {
  opacity: 1 !important;
  transform: translateX(10px);
}

a.carousel-control-next {
  opacity: 0 !important;
  transform: translateX(10px);
  transition: all 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
}

.Hero:hover a.carousel-control-next {
  opacity: 1 !important;
  transform: translateX(0%);
}

a.carousel-control-prev span.visually-hidden,
a.carousel-control-next span.visually-hidden {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
  visibility: visible !important;
  opacity: 1 !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 29px !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  clip: unset !important;
  color: transparent;
}

span.lazy-load-image-background {
  display: flex !important;
}

a.carousel-control-next span.visually-hidden {
  transform: rotate(180deg);
  margin-left: 29px !important;
  margin-right: 0 !important;
}

.BgHero img {
  width: 100%;
}

.AnimatedFlipp img {
  width: 100%;
  height: 180px;
  display: flex;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.img_work_featured {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.AnimatedFlipp {
  margin-left: auto;
}

.MainHero {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 242px;
  padding-bottom: 182px;
  position: relative;
}

.MainHero span.lazy-load-image-background {
  position: absolute;
  inset: 0px;
  height: 100%;
  width: 100%;
}

.MainHero span.lazy-load-image-background img {
  height: 100%;
  width: 100%;
}

.MainHero::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(to left bottom, #ff000000, #00000045);
}

.MainHero .container-max {
  z-index: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
}

/* .Content_Hero {
  margin-top: -150px;
  width: 50%;
} */

.Content_Hero h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 46px;
  text-transform: uppercase;
  font-weight: 400;
}

.Content_Hero h1 strong {
  display: inline-block;
}

.Content_Hero p {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  width: 95%;
  margin: 13px 0 20px;
}

.Content_Hero a {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

.imageAndContentMain {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

h1.heading_animated {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  align-items: center;
  justify-content: flex-start;
  font-size: 29px;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  color: rgb(0, 0, 0);
  transform: rotateY(180deg);
  text-align: left;
  line-height: 33px;
  padding: 20px;
  border-radius: 5px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.imageAndContentMain.animated {
  transform: rotateY(180deg);
}

/* sec 2 css  */
.h4-sec-2 p {
  font-size: 48px;
  line-height: 54px;
  font-weight: 300;
}

.h4-sec-2 p strong {
  font-weight: 700;
}

.section2 .container-max {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  align-items: center;
  column-gap: 40px;
  padding: 60px 0;
}

.right-sec-2 img {
  width: 85%;
}

.right-sec-2 {
  text-align: right;
}

.p-sec-2 {
  margin: 50px 0;
  width: 80%;
  padding-left: 25px;
  position: relative;
}

.p-sec-2 p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.p-sec-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #e50000;
}

/* sec 2 css  */

/* sec 3 css  */
.section3 .container-max {
  position: relative;
}

.section3 .container-max Button {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%22http://www.w3.org/2000/svg%22 aria-hidden=%22true%22 class=%22svg-inline--fa fa-long-arrow-right fa-w-14%22 data-icon=%22long-arrow-right%22 data-prefix=%22fal%22 viewBox=%220 0 448 512%22%3e%3cpath fill=%22%23fff%22 d=%22M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z%22%3e%3c/path%3e%3c/svg%3e");
  background-color: transparent;
  color: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
  filter: brightness(0.5);
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  background-repeat: no-repeat;
  margin-top: 15px;
}

.section3 .container-max .rec-arrow-left {
  transform: rotate(180deg);
  margin-right: 46px;
}

.section3 .container-max Button:hover,
.section3 .container-max Button:hover:enabled,
.section3 .container-max Button:focus:enabled {
  background-color: transparent !important;
  color: transparent !important;
}

button:disabled {
  opacity: 0;
  visibility: hidden;
}

.main-carr {
  width: 100%;
  margin-right: 30px;
}

.main-carr a {
  display: block;
  cursor: pointer;
  text-decoration: underline !important;
}

.rec-slider-container {
  margin: 0 !important;
}

.img-main img {
  width: 100%;
  height: 500px;
  border: 1px solid #e50000;
}

.image-btn {
  position: absolute;
  bottom: 0;
  right: -100%;
  background: white;
  padding: 28px 40px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  color: #e50000;
  transition: 0.5s all;
  border: 1px solid #e50000;
}

.img-main {
  position: relative;
  overflow: hidden;
  display: flex;
}

.img-main:hover .image-btn {
  right: 0%;
  transition: 0.5s all;
}

.image-btn:hover {
  background: #e50000;
  color: #fff;
  transition: 0.5s all;
}

.recent-work {
  color: #e50000;
  font-size: 45px;
  font-weight: lighter;
  margin-bottom: 22px;
}

.recent-work span {
  margin-left: 10px;
  font-weight: bold;
}

p.itservi {
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 0.4rem;
  color: #292929;
  opacity: 0.4;
  text-transform: uppercase;
  font-size: 15px;
}

.main-carr h6 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
}

.itexce {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
}

.rec-pagination {
  display: none !important;
}

/* sec 3 css  */

/* sec 4 css  */
.section4 {
  margin: 60px 0;
}

.main-img-boxex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 50px;
  text-align: center;
}

.main-img-boxex img {
  width: 175px;
}

.img-boxes p {
  color: #adadad;
  transition: color 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
  text-transform: uppercase;
  letter-spacing: 1.35px;
  margin: 0;
}

.img-boxes:hover p {
  color: black;
}

.img-boxes {
  border-top: 2px solid #e50000;
  padding-bottom: 30px;
  padding-top: 30px;
}

.img-boxes a {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 125px;
  gap: 10px;
}

h2.recent-work.r2 {
  margin-bottom: 50px;
}

.main-img-boxex .img-boxes:nth-child(1) img {
  width: 125px;
}

.main-img-boxex .img-boxes:nth-child(2) img {
  filter: invert(1);
  width: 200px;
}

.main-img-boxex .img-boxes:nth-child(3) img {
  width: 130px;
}

.main-img-boxex .img-boxes:nth-child(4) img {
  width: 150px;
}

.main-img-boxex .img-boxes:nth-child(5) img {
  filter: drop-shadow(0px 0px 1px black);
  width: 220px;
}

.main-img-boxex .img-boxes:nth-child(6) img {
  filter: brightness(0.5);
}

.main-img-boxex .img-boxes:nth-child(8) img {
  width: 240px;
}

/* sec 4 css  */

/* contact form section  */


.contact-main-Wrapper {
  position: relative;
  overflow: hidden;
}

.contactform {
  background-color: #fff;
  max-width: 643px;
  border-top: 7px solid #e50000;
  padding: 30px;
  z-index: 990;
  margin-left: auto;
}

.contactform h3 {
  font-size: 48px;
  line-height: 54px;
  font-weight: 700;
}

.Main-Form-Background span:not(.globalanimatedbtn span) {
  margin: 8px 0;
  display: block;
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
  text-transform: lowercase;
}

.contact-us-page {
  position: relative;
}

.Main-Form-Background {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center; */
  max-width: 1600px;
  margin: auto;
  position: relative;
  height: 100%;
  align-items: flex-end;
  padding-top: 130px;
  /* background-image: unset !important; */
  background: linear-gradient(45deg, #ffffff, #e50000, #e50000, #ffffff);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

.Main-Form-Background:not(.contact-us-page .Main-Form-Background) {
  padding-top: 80px;
}

.Main-Form-Background img {
  width: 580px;
  margin-left: auto;
  z-index: 99999;
  position: relative;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.contact-main-div {
  position: relative;
  display: inline-block;
  width: 100%;
}

.contactform form {
  margin: 20px 0 0;
}

.contactform form input:not(.globalanimatedbtn) {
  color: #172121;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #bfbaba;
  margin-bottom: 10px;
}

input#submit {
  display: none !important;
}

.submit-btn {
  margin-top: 10px;
}

.contactform form label:not(.globalanimatedbtn) {
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  color: #172121;
  text-transform: uppercase;
}

.contactform form input#submit:not(.globalanimatedbtn) {
  color: #e50000;
  appearance: none;
  border: none;
  cursor: pointer;
  margin-right: 3rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  line-height: 20px;
  margin-top: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
  background: #e50000;
  color: white !important;
  width: fit-content;
  padding: 10px 25px !important;
  border-radius: 4px;
}

/* contact form section  */

/* footer css  */
footer {
  padding: 60px 0 20px;
}

footer h5 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 20px;
}

footer h5 strong {
  font-weight: 700;
}

.footer__top__contact {
  display: flex;
  flex-direction: column;
}

.footer__top__contact a {
  color: #e50000;
  text-decoration: none;
  font-size: 30px;
  line-height: 34px;
  font-weight: 500;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 40px;
  border-bottom: 1px solid #6f727440;
  margin-bottom: 30px;
}

footer .menus ul li {
  display: inline-block;
  list-style: none;
}

footer .menus ul {
  padding: 0;
  margin: 0;
}

footer .menus ul li a {
  color: #292929;
  text-decoration: none;
  width: auto;
  position: relative;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}

footer .footer-bot-navlink .menus ul li {
  display: none;
}

footer .footer-bot-navlink .menus ul li:nth-child(9),
footer .footer-bot-navlink .menus ul li:nth-child(8) {
  display: inline-block;
}

footer .footer-bot-navlink .menus ul li {
  padding: 0;
}

footer .footer-bot-navlink .menus ul li a,
.footer-bot-navlink p {
  color: #292929;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  padding: 0;
  margin-right: 1.75rem;
}

.footer-bot-navlink {
  display: flex;
}

.footer-bot {
  display: flex;
  justify-content: space-between;
}

.footer__bot__address {
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  color: #292929;
}

.footer__bot__address a {
  color: #292929;
}

.footer-bot-row-social-icons svg {
  width: 22px;
  color: #e50000;
  margin: 0 9px;
  font-size: 20px;
}

/* footer css  */
.ClientFeedback h2 {
  color: #e50000;
  font-size: 45px;
  font-weight: lighter;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.flex-clients-says {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;
  padding: 20px 0 70px;
}

.flex-clients-says video {
  width: 100%;
}

.flex-clients-says p {
  font-size: 24px;
}

.flex-clients-says br {
  margin-top: 10px;
}

.flex-clients-says p {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 400;
}

/* responsive css  */
@media (min-width: 1025px) {
  .burger-btn {
    display: none;
  }
}

@media (max-width: 1300px) {
  .container-max {
    max-width: 90%;
  }

  .Hero .carousel.slide {
    height: 700px;
  }

  .main-carr {
    width: 100%;
  }

  .main-img-boxex {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .Main-Form-Background {
    max-width: 100%;
  }

  header.header-normal .container-max {
    max-width: 93%;
  }
}

@media (max-width: 1100px) {
  .Content_Hero h1 {
    font-size: 42px;
  }

  .Content_Hero p {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1025px) {
  .MainHero .container-max {
    align-items: flex-start;
  }

  h1.heading_animated {
    font-size: 21px;
    line-height: 27px;
  }

  .Content_Hero h1 {
    font-size: 36px;
    padding: 00;
  }

  .Content_Hero p {
    font-size: 21px;
    line-height: 25px;
  }

  .AnimatedFlipp img {
    height: 140px;
  }

  .img_work_featured {
    gap: 15px;
  }

  .hero-title {
    max-width: 95%;
    width: 95%;
    margin-bottom: -100px;
  }

  .rec-slider-container {
    margin: 0 !important;
  }

  .section2 .container-max {
    column-gap: 0px;
  }

  .h4-sec-2 p {
    font-size: 40px;
  }

  .header-cta {
    display: none;
  }

  header.header-normal .container-max nav {
    display: none;
  }

  body.home-page-body header.header-normal.header-bg svg path {
    fill: #e50000;
  }

  header.header-normal .container-max {
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  header a.logo-link {
    margin-left: 0;
  }

  body.home-page-body.home-page header.header-normal svg path,
  .contact-page header.header-normal svg path {
    fill: white;
    stroke: #fff;
  }

  body.home-page-body.home-page header.header-normal.header-bg svg path,
  .contact-page header.header-normal.header-bg svg path {
    fill: #e50000;
    stroke: #e50000;
  }


  .burger-btn svg {
    font-size: 35px;
    transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
    color: white;
  }

  header.header-normal .container-max nav.responsive_nav {
    display: block;
  }

  header.header-normal .container-max nav.responsive_nav::after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: #e50000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  header li a {
    font-size: 28px !important;
    color: white !important;
    font-weight: 400 !important;
    margin: 7px 0 !important;
    letter-spacing: 2px !important;
  }

  .burger-btn.active svg path {
    fill: #fff !important;
  }


  .burger-btn {
    z-index: 4;
    position: relative;
  }

  nav.responsive_nav .menus {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  header li {
    display: block;
    text-align: center;
  }

  header li a {
    font-size: 20px;
    padding: 10px;
    display: inline-block;
  }

  header a.logo-link {
    width: 85px;
  }

  header.header-normal.header-bg svg.svg-inline--fa.fa-bars {
    color: black;
    transition: 0.5s cubic-bezier(0.56, 0.01, 0.4, 1);
  }

  .footer-top {
    flex-direction: column;
    align-items: unset;
    padding-bottom: 0;
  }

  .nav-footer {
    text-align: center;
    padding: 25px 0 0;
    padding-bottom: 30px;
  }

  .footer-bot {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    text-align: center;
  }

  .footer-bot-navlink {
    grid-column: auto / span 2;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 20px 0;
  }

  .footer-bot-navlink .burger-btn {
    display: none;
  }
}

@media (max-width: 900px) {
  .flex-clients-says {
    grid-template-columns: 1fr;
  }

  .section2 .container-max {
    grid-template-columns: 1fr;
  }

  .right-sec-2 img {
    width: 100%;
  }

  .hero-title h1 {
    font-size: 45px;
  }

  .hero-title p {
    font-size: 22px;
    padding-right: 156px;
  }

  .img-main img {
    height: 400px;
  }
}

@media (max-width: 870px) {
  .main-img-boxex {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .img-boxes {
    border: 1px solid #e50000;
    border-collapse: collapse;
    margin: 10px;
  }
}

@media (max-width: 768px) {
  .MainHero .container-max {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .Content_Hero p {
    width: 75%;
    font-size: 20px;
  }

  .Content_Hero,
  .AnimatedFlipp {
    width: 100%;
    margin: 0;
  }

  .MainHero {
    padding-top: 150px;
    padding-bottom: 65px;
  }

  .AnimatedFlipp img {
    height: 230px;
  }
}

@media (max-width: 700px) {
  .footer-bot {
    display: block;
    text-align: center;
  }

  .footer-bot-row-social-icons {
    margin-top: 20px;
  }

  .img-main img {
    height: 300px;
  }
}

@media (max-width: 600px) {
  .main-img-boxex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .main-img-boxex .img-boxes {
    margin: 0;
    width: 48%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .img-boxes a {
    height: auto;
  }

  .main-img-boxex .img-boxes:nth-child(1) img {
    width: 110px;
  }

  .main-img-boxex .img-boxes:nth-child(2) img {
    width: 150px;
  }

  .main-img-boxex .img-boxes:nth-child(4) img {
    width: 130px;
  }

  .img-boxes p {
    font-size: 14px;
  }

  .main-img-boxex .img-boxes:nth-child(5) img {
    width: 150px;
  }

  .main-img-boxex .img-boxes:nth-child(6) img {

    width: 140px;
  }

  .main-img-boxex .img-boxes:nth-child(7) img {
    width: 140px;
  }

  .main-img-boxex .img-boxes:nth-child(8) img {
    width: 160px;
  }






  header a.logo-link {
    width: 68px;
  }

  .Hero .carousel.slide {
    height: 500px;
  }

  .hero-title h1 {
    font-size: 28px;
    line-height: 40px;
  }

  .hero-title p {
    font-size: 16px;
    padding-right: 0;
    line-height: 22px;
  }

  .section2 .container-max {
    padding: 0px 0 40px;
  }

  .h4-sec-2 p {
    font-size: 25px;
    line-height: 35px;
  }

  .p-sec-2 p {
    font-size: 17px;
    line-height: 25px;
    margin: 0;
  }

  .p-sec-2 {
    margin: 20px 0;
    width: 100%;
    padding-left: 20px;
  }

  .right-sec-2 {
    margin-top: 30px;
  }

  .main-carr {
    margin-left: 0;
    margin-right: 0;
  }

  .section4 {
    margin: 30px 0;
  }

  h2.recent-work.r2 {
    margin-bottom: 30px;
    font-size: 35px;
  }

  .hero-title {
    margin-bottom: -50px;
  }

  .contactform {
    padding: 30px 20px;
    padding-top: 50px;
  }

  .contactform h3 {
    font-size: 30px;
    line-height: 38px;
  }

  .contactform span {
    font-size: 18px;
  }

  .contactform form {
    margin: 20px 0;
  }
}

@media (max-width: 450px) {
  .flex-clients-says p {
    font-size: 18px;
  }

  .flex-clients-says {
    padding: 20px 0 32px;
  }

  .AnimatedFlipp {
    display: none;
  }

  .Content_Hero h1 {
    font-size: 30px;
  }

  .Content_Hero p {
    width: 100%;
  }

  .footer-bot-navlink {
    display: grid;
    gap: 15px;
  }

  .footer__bot__address {
    font-size: 15px;
  }

  .img-main img {
    height: 250px;
  }
}

/* Services Page Css  */

body.home-page-body .section2-services {
  padding-top: 150px;
}

body.home-page-body .section2-services h2 {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  color: #e50000;
  font-size: 60px;
  line-height: 64px;
}

body.home-page-body .section2-services h2 strong {
  font-weight: 700;
}

body.home-page-body .section2-services p {
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
  width: 65%;
  padding-top: 10px;
}

body.home-page-body .section2-services p strong {
  font-weight: 600;
}

body.home-page-body header.header-normal a.logo-link:after {
  background-color: #fff;
}

.Services-sections .service-inner h2 {
  font-size: 60px;
  line-height: 64px;
  font-weight: 700;
  color: black;
  margin: 0;
}

.Services-sections .service-inner p {
  font-size: 48px;
  line-height: 54px;
  color: black;
  font-weight: 300;
  padding-right: 50px;
  text-transform: capitalize;
}

a.Services-sections {
  padding: 40px 0;
  border-bottom: 1px solid #6f72744d;
  transition: 0.5s all;
  display: block;
}

a.Services-sections:hover {
  color: #fff;
  background-color: #e50000;
  transition: 0.5s all;
}

a.Services-sections:hover .service-inner p,
a.Services-sections:hover .service-inner h2 {
  color: #fff;
}

.service-inner {
  position: relative;
}

.service-inner::after {
  background-image: url("../src/components/images/arrow.webp");
  content: "";
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-size: 100% 100%;
  background-position: center;
}

a.Services-sections:hover .service-inner::after {
  filter: brightness(2.5);
}

@media (max-width: 1025px) {
  a.Services-sections .container-max {
    max-width: 93%;
  }
}

@media (max-width: 768px) {
  body.home-page-body .section2-services p {
    width: 100%;
  }

  body.home-page-body .section2-services h2 {
    font-size: 40px;
    line-height: normal;
  }

  body.home-page-body .section2-services p {
    font-size: 20px;
  }

  .Services-sections .service-inner h2 {
    font-size: 50px;
  }

  .Services-sections .service-inner p {
    font-size: 38px;
    margin-bottom: 30px;
  }

  .service-inner::after {
    top: unset;
    transform: translate(0, -40%);
    left: 0;
  }
}

@media (max-width: 500px) {
  .Services-sections .service-inner h2 {
    font-size: 35px;
    line-height: normal;
  }

  .Services-sections .service-inner p {
    font-size: 25px;
    margin-bottom: 20px;
    line-height: normal;
  }
}

/* work page css */

h2.our-work-heading {
  display: block;
  font-weight: 300;
  text-transform: uppercase;
  color: #e50000;
  font-size: 60px;
  line-height: 64px;
}

.Section-1-Our-Work {
  padding-top: 150px;
}

.content {
  display: none;
}

.show-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.show-content a {
  width: 100%;
}

.show-content img {
  width: 100%;
  height: 300px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.037);
}

.hide-tabs-dropdown,
.hide-tabs-dropdown1 {
  max-height: 0;
  overflow: hidden;
}

h2.our-work-heading strong {
  font-weight: 700;
}

.main-tabs {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 0.3fr 1.7fr;
  gap: 20px;
}

.inner-tab-left-main h2 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 300;
  text-transform: uppercase;
  color: #e50000;
  cursor: pointer;
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.inner-tab-left-main h2 svg {
  font-size: 18px;
}

.svg.svg--chevron-down.sidebar-filter__cat__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

h2.arrow-top .svg.svg--chevron-down.sidebar-filter__cat__arrow {
  transform: scale(-1);
}

.tabs-single,
.highlighted {
  padding-bottom: 0.5rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4em;
  font-size: 20px;
  cursor: pointer;
}

.tabs-single:hover,
.highlighted:hover {
  color: #e50000;
}

.highlighted {
  color: #e50000;
  text-decoration: underline;
}

.inner-tab-left-main {
  padding-bottom: 30px;
}

a.content-anchor p {
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  color: #fff;
  transition: transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
  transform: translate(0px, 2rem);
  margin-bottom: 10px;
  background: #e50000;
  padding: 8px;
  border-radius: 3px;
}

a.content-anchor:hover p {
  background: transparent;
  padding: 0;
}

a.content-anchor span:not(.lazy-load-image-background) {
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: white;
  opacity: 0;
  overflow: hidden;
  transition: transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1),
    -webkit-transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
  display: flex;
  align-items: center;
}

.content-dis {
  position: absolute;
  bottom: 0;
  padding: 22px 30px;
  z-index: 9;
}

a.content-anchor {
  display: flex;
  position: relative;
  border: 1px solid #e50000;
}

a.content-anchor:hover p {
  transform: translate(0px, 0rem);
  transition: transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1),
    -webkit-transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
}

a.content-anchor:hover span {
  opacity: 1;
  overflow: unset;
  transition: transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1),
    -webkit-transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
}

a.content-anchor::after {
  top: 0;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #e50000;
  opacity: 0;
  z-index: 2;
  -webkit-transition: opacity 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
  -o-transition: opacity 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
  transition: opacity 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
}

a.content-anchor:hover::after {
  opacity: 0.82;
  transition: transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1),
    -webkit-transform 0.25s cubic-bezier(0.56, 0.01, 0.4, 1);
}

/* about us page  */

div .section1-hero {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.section1-hero::after {
  background: #00000061;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

.section1-hero::after,
.Main-Form-Background::after,
.Main-Form-Background::after {
  background-image: linear-gradient(4deg, transparent, rgb(0 0 0 / 74%));
  /* content: ""; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 300;
  pointer-events: none;
}

.hero_about_bottom .section1-hero::after,
.Main-Form-Background::after,
.Main-Form-Background::after {
  background-image: linear-gradient(199deg, transparent, rgb(0 0 0 / 74%));
}

div .section1-hero .container-max {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 80px;
}

.content-hero h2 {
  text-transform: uppercase;
  margin: 0;
  font-size: 60px;
  line-height: 64px;
  color: #fff;
  font-weight: 300;
}

.content-hero h2 strong {
  font-weight: 700;
}

.content-hero {
  z-index: 999;
  width: 60%;
}

.content-hero p {
  line-height: 30px;
  letter-spacing: 0.6px;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  padding: 15px 200px 15px 0;
}

.content-hero a {
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: inherit;
  color: #e50000;
  display: flex;
  width: fit-content;
  align-items: center;
}

.content-hero a svg {
  font-size: 21px;
}

.section-3-single-bxs {
  background-color: #e50000;
  padding: 100px 0;
  color: white;
}

.section-3-single-bxs h2 {
  font-size: 48px;
  line-height: 54px;
  font-weight: 300;
}

.section-3-single-bxs h2 strong {
  font-weight: 700;
}

.section-3-single-bxs p {
  font-size: 19px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.process-steps-copy {
  width: 36%;
}

.main-content-3-bxs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 50px 0 0;
}

.content-single-bx {
  border-left: 2px solid white;
  padding: 10px 20px;
}

.content-single-bx h4 {
  font-size: 30px;
  line-height: 34px;
  font-weight: 500;
}

.section-about .container-max {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 130px 0;
}

.righ-sec-about h2 {
  font-size: 48px;
  line-height: 54px;
  font-weight: 300;
  color: #fff;
}

.righ-sec-about h2 strong {
  font-weight: 700;
}

.righ-sec-about p {
  font-size: 30px;
  line-height: 34px;
  font-weight: 500;
  margin-top: 20px;
  color: #fff;
}

.section-about {
  background-image: url("./components/images/bg-img3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}

.righ-sec-about {
  background-color: #010101a6;
  padding: 40px;
}

.dublicate-hero .content-hero h2 {
  display: flex;
  flex-direction: column;
  font-size: 48px;
  line-height: 54px;
  font-weight: 300;
}

.dublicate-hero .content-hero a,
.dublicate-hero .content-hero p {
  display: none;
}

.map-section {
  background-color: #000;
  padding: 130px 0;
  color: #fff;
  position: relative;
}

.map-section img {
  width: 100%;
  height: 100%;
}

.right-map-section {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.right-map-section iframe {
  width: 100%;
  height: 100%;
}

.left-map-section h1 {
  display: flex;
  font-weight: 300;
  flex-direction: column;
  font-size: 48px;
  line-height: 54px;
}

.left-map-section h1 strong {
  font-weight: 700;
  margin-bottom: 20px;
}

.location-footer-location h4 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
}

.location-footer-location a {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
}

a.buttncn7 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  color: #e50000;
  width: fit-content;
  margin-top: 30px;
}

.location-footer-location {
  width: fit-content;
}

/* about us page  */

/* contact us page  */
.Main-Form-Background::after {
  background-color: #000;
  opacity: 0.65;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
}

.first-des {
  background-color: #f6f6f6;
  padding: 80px 0;
}

.left-adress h3 {
  position: relative;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
}

.left-adress h3::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translate(1rem, -50%);
  -ms-transform: translate(1rem, -50%);
  transform: translate(1rem, -50%);
  width: 2rem;
  height: 1px;
  background-color: #172121;
}

.stylesame-p {
  margin: 0;
  color: #6f7274;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

.stylesame-a {
  display: block;
  font-size: 24px;
  line-height: 27px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 0;
  text-decoration: none;
  color: #172121;
}

p.contact-info__contact-titlep2.stylesame-p,
p.contact-info__contact-titlep2.stylesame-p {
  margin-top: 30px;
}

.second-des {
  background: #e50000;
  color: white;
  padding: 80px 0;
}

.second-des .right-adress a {
  color: #fff;
  margin: 3px 0;
  display: inline;
  font-size: 24px;
  line-height: 35px;
  font-weight: 300;
}

.first-des .container-max {
  display: grid;
  grid-template-columns: 0.8fr 0.3fr 0.8fr;
}

.first-des .more-right-inner-right {
  padding-left: 300px;
}

.second-des .container-max {
  display: grid;
  grid-template-columns: 0.8fr 0.3fr 0.8fr;
}

.second-des .more-right-inner-right {
  text-align: right;
}

.second-des .more-right-inner-right a {
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: white;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.second-des .more-right-inner-right a svg {
  font-size: 23px;
}

.second-des .left-adress h3::after {
  background-color: #fff;
}

.second-des.third-des {
  border: 1px solid #ffffff7a;
}

.Main-Form-Background h2 {
  color: white;
  z-index: 990;
  font-weight: 700;
  font-size: 60px;
  padding-left: 130px;
  padding-bottom: 40px;
}

/* contact us page  */

.nav-footer .burger-btn {
  display: none;
}

/* media quiries  */
@media (max-width: 1490px) {
  .Main-Form-Background h2 {
    padding-left: 56px;
  }
}

@media (max-width: 1280px) {


  .first-des .more-right-inner-right {
    padding-left: 58%;
  }

  .section-about .container-max {
    grid-template-columns: 0.8fr 1.2fr;
    padding: 51px 0;
  }

  .footer-bot {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footer-bot-row-social-icons {
    text-align: center;
  }

  .footer-bot-navlink {
    justify-content: flex-end;
  }
}

@media (max-width: 1150px) {
  .contactform {
    max-width: 100%;
    position: relative;
    padding: 50px;
  }

  .contactform form {
    margin: 20px 0;
  }

  .first-des .container-max,
  .second-des .container-max {
    display: grid;
    grid-template-columns: 0.7fr 0.6fr 0.7fr;
  }

  .first-des .more-right-inner-right {
    padding-left: 110px;
  }

  .dublicate-hero .content-hero h2 {
    font-size: 39px;
  }

  .left-map-section h1 {
    font-size: 40px;
    margin: 0;
    line-height: 43px;
  }

  .location-footer-location a {
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 8px;
    display: inline-block;
  }

  .location-footer-location h4 {
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 1px;
  }
}

@media (max-width: 992px) {
  .content-hero {
    width: 90%;
  }

  .show-content {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .process-steps-copy {
    width: 75%;
  }

  .section-3-single-bxs {
    padding: 60px 0;
  }

  .section-3-single-bxs p {
    font-size: 23px;
    margin-left: 3px;
  }

  .main-content-3-bxs {
    grid-template-columns: 1fr 1fr;
  }

  .section-about .container-max {
    grid-template-columns: 1fr;
  }

  .right-map-section {
    position: relative;
    width: 100%;
    margin-top: 40px;
  }

  .map-section {
    padding: 60px 0 60px;
  }
}

@media (max-width: 769px) {
  .Main-Form-Background h2 {
    font-size: 40px;
    padding-left: 32px;
  }

  .Main-Form-Background {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 400px;
  }

  .Main-Form-Background:not(.contact-us-page .Main-Form-Background) {
    padding-top: 40px;
  }

  .Main-Form-Background img {
    width: 100%;
  }

  .contactform {
    padding: 50px 40px;
  }

  .first-des .more-right-inner-right {
    padding-left: 0;
  }

  .first-des .container-max,
  .second-des .container-max {
    display: flex;
    flex-direction: column;
  }

  .left-adress,
  .right-adress {
    margin-bottom: 15px;
  }

  p.contact-info__contact-titlep2.stylesame-p,
  p.contact-info__contact-titlep2.stylesame-p {
    margin-top: 15px;
  }

  .second-des .more-right-inner-right a {
    justify-content: flex-start;
  }

  .right-adress {
    width: 250px;
  }

  .footer-bot {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .footer-bot-navlink {
    grid-column: unset;
    display: block;
    margin: 0;
  }

  .footer-bot-navlink p {
    margin: 0;
  }

  .nav-footer {
    padding: 20px 0 0;
  }

  footer .menus ul li a {
    margin-bottom: 10px;
    display: inline-block;
  }

  .main-content-3-bxs {
    grid-template-columns: 1fr;
  }

  .main-tabs {
    grid-template-columns: 1fr;
  }

  .only-tabs {
    display: grid;
    flex-wrap: wrap;
    gap: 60px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 550px) {
  div .section1-hero {
    height: 500px;
  }

  .show-content {
    gap: 10px;
  }

  .first-des,
  .second-des {
    padding: 40px 0;
  }


  .contactform h3 {
    font-size: 35px;
    line-height: normal;
  }

  .contactform {
    padding: 40px 20px;
  }

  .content-hero h2 {
    font-size: 35px;
    line-height: normal;
  }

  .content-hero p {
    line-height: normal;
    font-size: 20px;
    padding: 10px 20px 0px 0;
  }

  .content-hero a {
    font-size: 17px;
    letter-spacing: 1px;
  }

  .section-3-single-bxs h2 {
    font-size: 35px;
    line-height: normal;
  }

  .process-steps-copy {
    width: 100%;
  }

  .section-3-single-bxs p {
    font-size: 20px;
    line-height: normal;
  }

  div .section1-hero .container-max {
    padding-bottom: 50px;
  }

  .content-single-bx {
    padding-right: 0;
  }

  .righ-sec-about h2 {
    font-size: 35px;
    line-height: normal;
  }

  .righ-sec-about p {
    font-size: 20px;
    line-height: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  .section-about .container-max {
    padding: 40px 0;
  }

  .section1-hero {
    height: unset;
    padding: 50% 0 0 0;
  }

  .dublicate-hero .content-hero h2 {
    font-size: 35px;
    line-height: normal;
  }

  .left-map-section h1 {
    font-size: 35px;
    line-height: normal;
  }

  h2.our-work-heading {
    font-size: 40px;
  }

  .only-tabs {
    gap: 50px;
    grid-template-columns: 1fr 1fr;
  }

  .Main-Form-Background h2 {
    padding-left: 16px;
  }
}

@media (max-width: 370px) {
  .only-tabs {
    gap: 0px;
    grid-template-columns: 1fr;
  }
}

/* TopLoadingBar.css */
.top-loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #29b6f6;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.top-loading-bar.visible {
  opacity: 1;
  visibility: visible;
}

.progress {
  height: 100%;
  width: 0;
  background-color: #ff8f00;
  transition: width 0.3s;
}


/* blog page css start */
/* Loading animation css start  */

.loadingwrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadingwrapper .circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #e50000;
  left: 15%;
  transform-origin: 50%;
  animation: circle .5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.loadingwrapper .circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.loadingwrapper .circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.loadingwrapper .shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow .5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.loadingwrapper .shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.loadingwrapper .shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.loadingwrapper span {
  position: absolute;
  top: 71px;
  font-size: 27px;
  letter-spacing: 12px;
  color: #e50000;
  left: 4%;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.loading-animation {
  background: #fff;
  position: relative;
  grid-column: 1 / 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 500px;
  width: 100%;
  z-index: 99;
}

/* Loading animation css end  */
.Main_Blogs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  position: relative;
}

.imageAndCatBlogs img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  display: flex;
}

.imageAndCatBlogs {
  position: relative;
}

.imageAndCatBlogs span {
  background: #e50000;
  color: white;
  padding: 6px 20px;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.Single_Blog {
  box-shadow: 0px 0px 8px 1px #00000030;
  border-radius: 8px;
}

.Blog_content {
  padding: 20px;
}

body.home-page-body .Blog_content h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: normal;
}

body.home-page-body .Blog_content p {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding-top: 3px;
}



/* Blog Page Css Start  */
.All_Blogs_Main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  position: relative;
}

.All_Blogs_Main img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.Single_Blog_Main img {
  width: 250px;
  height: 250px;
}

.main-div-blogs-page {
  display: grid;
  grid-template-columns: .5fr 1.5fr;
}

.main-div-blogs-page .sidebar ul {
  list-style: none;
}

.main-div-blogs-page .sidebar ul li {
  font-size: 1.2rem;
  font-family: 'FuturaPT';
  margin: 3px 0;
  color: #aaaaaa;
  cursor: pointer;
}

.main-div-blogs-page .sidebar ul li.active {
  color: black;
}

.main-div-blogs-page .sidebar input[type="text"] {
  margin-bottom: 10px;
  padding: 7px;
  border: 1px solid #aaaaaa;
  font-size: .9rem;
  width: 100%;
  letter-spacing: 2px;
  color: #aaaaaa;
}

.main-div-blogs-page .sidebar {
  padding-right: 50px;
  border-right: 1px solid #e5e5e5;
  margin-right: 50px;
}

.main-div-blogs-page .pos-sticky {
  position: sticky;
  top: 0%;
  transition: .5s all;
}

.Sticky_Barr .pos-sticky {
  position: sticky;
  top: 120px;
  transition: .5s all;
}

/* Blog Page Css End  */
/* awards section css start */
.main_awards_div_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.main_awards_div_flex img {
  padding: 5px 15px;
  height: auto;
  width: 135px;
}

.main_awards_div_flex a.award-single-box:nth-child(1) img {
  width: 300px;
}

.Section_awards {
  margin: 50px auto;
}

@media(max-width:500px) {
  .main_awards_div_flex img {
    width: 90px;
  }

  .Main-Form-Background {
    padding-top: 0;
  }
}

/* awards section css end */